import Translate from "@components/Translate"
import { Label } from "@rebass/forms"
import { useAskForBudget } from "@utils/hooks/useAskForBudget"
import { useTranslate } from "@utils/hooks/useTranslate"
import React from "react"
import * as Style from "./Contact.styles"
import { InputTelefone, Input } from "@xapps/design-system"
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input"

const customValidate = values => {
  let errors = {}
  if (!values.phone) {
    errors.phone = "Campo obrigatório"
  } else if (
    !!values.phone &&
    !isPossiblePhoneNumber(values.phone) &&
    !isValidPhoneNumber(values.phone)
  ) {
    errors.phone = "Número de telefone inválido"
  }
  return errors
}

function Contact() {
  const formatMessage = useTranslate()

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
  } = useAskForBudget(undefined, undefined, customValidate)

  return (
    <Style.Content>
      <Style.Wrapper onSubmit={handleSubmit}>
        <Label>
          <Translate id="contact.firstName" />
          <Input
            id="firstName"
            name="firstName"
            required
            value={values.firstName}
            rotulo={formatMessage("contact.placeholders.firstName")}
            onChange={handleChange}
          />
        </Label>

        <Label>
          <Translate id="contact.lastName" />
          <Input
            name="lastName"
            required
            value={values.lastName}
            rotulo={formatMessage("contact.placeholders.lastName")}
            onChange={handleChange}
          />
        </Label>

        <Label>
          <Translate id="contact.phone" />
          <InputTelefone
            erro={errors["phone"]}
            value={values.phone}
            onChange={handleChange}
            required
            international
            lingua={process.env.GATSBY_LANGUAGE}
            defaultCountry={process.env.GATSBY_LANGUAGE === "en" ? "US" : "BR"}
            nome="phone"
            rotulo={formatMessage("contact.placeholders.phone")}
          />
        </Label>

        <Label>
          <Translate id="contact.email" />
          <Input
            name="email"
            type="email"
            required
            value={values.email}
            rotulo={formatMessage("contact.placeholders.email")}
            onChange={handleChange}
          />
        </Label>
        <Style.SubmitButton type="submit" isSubmitting={isSubmitting}>
          <Translate id="contact.send" />
        </Style.SubmitButton>
      </Style.Wrapper>
    </Style.Content>
  )
}

export default Contact
