import BaseLayout from "@components/BaseLayout"
import Contact from "@components/Contact"
import Hero from "@components/Hero"
import Seo from "@components/Seo"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

function ContactPage() {
  const { metatag } = useStaticQuery(graphql`
    query Contact {
      metatag: markdownRemark(
        fileAbsolutePath: { glob: "**/content/metatags/solicitar-orcamento.md" }
      ) {
        frontmatter {
          languages {
            language
            keywords
            title
            description
          }
        }
      }
    }
  `)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  return (
    <BaseLayout
      hideFooter
      hideHeaderButtons
      hideMenu
      isDark
      hero={
        <Hero title="contact.hero.title" subtitle="contact.hero.subtitle" />
      }
    >
      <Seo {...metatags} />
      <Contact />
    </BaseLayout>
  )
}

export default ContactPage
