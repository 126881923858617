import { WidthLimitation } from "@components/Content"
import { Botao } from "@xapps/design-system"
import React from "react"
import { Box, Flex } from "rebass"

export const Wrapper = props => (
  <Box
    as="form"
    width={1}
    backgroundColor="white"
    sx={{
      m: "0 auto",
      maxWidth: "370px",
      p: 4,
      label: {
        mb: 3,
      },
    }}
    {...props}
  />
)
export const SubmitButton = ({ children, isSubmitting, ...props }) => (
  <Botao
    mt="2"
    desabilitado={isSubmitting}
    estaCarregando={isSubmitting}
    {...props}
  >
    {children}
  </Botao>
)

export function Content({ children }) {
  return (
    <Flex
      backgroundColor="blue"
      bottom="0"
      position="absolute"
      top="0"
      width="100vw"
    >
      <WidthLimitation marginY="3" marginX="auto">
        {children}
      </WidthLimitation>
    </Flex>
  )
}
